
/**
 * @name: banner
 * @author: Gzm
 * @date: 2023-07-19 14:00
 * @description：banner
 * @update: 2023-07-19 14:00
 */
import {Component, Vue} from "vue-property-decorator";
import config from "@/config";
import {Message} from "element-ui";
import {ICrudOption} from "@/types/m-ui-crud";
import {
  bannerCreateApi,
  bannerListApi,
  bannerModifyApi,
  bannerRemoveApi,
  bannerSetUpApi
} from "@/apis/decorate";
import {ref} from "vue";
import {IPage} from "@/apis/page";
import {IBannerCreate} from "@/apis/decorate/types";
import {IConfig} from "@/config/types";

@Component({})
export default class BannerPage extends Vue {
  config: IConfig = config
  queryParam: IPage = {
    page: 1,
    limit: 10
  }


  tableData: any[] = []

  // 新增修改表单
  modelForm: IBannerCreate = {}

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: ICrudOption = {
    searchBox: false,
    addTitle: '新增轮播图片',
    editBtn: true,
    delBtn: true,
    column: [
      {
        label: "序号",
        type: "index",
        align: "center",
        addHide: true
      },
      // {
      //   label: "ID",
      //   prop: "id",
      //   align: 'center',
      //   width: 200,
      //   addHide:true,
      //   editHide:true,
      // },
      {
        label: "图片",
        prop: "pic",
        type: 'image',
        align: 'center',
        search: false,
        addSlot: true,
        editSlot: true,
        imgPrefix: config.downloadUrl,
        rules: [
          {required: true, message: '请上传轮播图片', trigger: 'blur'},
        ],
        span: 24,
        slot: true
      },


      {
        label: "状态",
        prop: "status",
        align: 'center',
        slot: true,
        addHide: true,
        editHide: true,
        value: 2,
        type: "switch",
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
      },
      {
        label: "创建时间",
        prop: "addTime",
        addHide: true,
        editHide: true,
        align: 'center',
      },
      {
        label: "最近一次经办人",
        prop: "realName",
        addHide: true,
        editHide: true,
        align: "center"
      },
      {
        label: "更新时间",
        prop: "editTime",
        addHide: true,
        editHide: true,
        align: 'center',
      },
    ]
  }

  /**
   * 获取数据
   */
  getList() {
    bannerListApi(this.queryParam).then(e => {
      this.tableData = e;
    })
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: any, val: number) {
    bannerSetUpApi(row.id).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList()
      }
    })
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg" && file.type != "image/gif") {
      Message.error("请选择正确格式")
      return false
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 500KB!');
    }
  }

  uploadSuccess(id: string, key: string, res: any, file: any){
    console.log(res)
    bannerModifyApi({id,pic:res.data.url}).then(e => {
      if (e){
        this.$message.success('操作成功！')
        this.getList()
      }
    })
  }

  /**
   * 新增提交
   * @param  form: any, done: any, loading: any
   */
  submitSave(form: any, done: any, loading: any) {
    bannerCreateApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 修改提交
   * @param  form: any, done: any, loading: any
   */
  submitEdit(form: any, done: any, loading: any) {
    bannerModifyApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  handleRemove(row: any) {
    this.$confirm('是否确认删除该轮播图片?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      bannerRemoveApi(row.id).then(e => {
        if (e) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList();
        }
      })
    }).catch(() => {
    });
  }

  created() {
    this.getList();
  }
}
